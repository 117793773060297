import React, { PropsWithChildren } from "react";

export interface props {
	title: string;
	icon: string;
	isHighlighted?: boolean;
}

const SkillElement: React.FC<PropsWithChildren<props>> = ({
	title,
	icon,
	isHighlighted = false,
}) => {
	return (
		<div
			className="relative w-full h-full overflow-hidden bg-black bg-opacity-20 rounded-lg border-primary backdrop-blur-md"
			style={{ backgroundColor: isHighlighted ? "#5500da66" : "#00000044" }}
		>
			<img
				className="absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/4 max-w-12 h-1/2"
				src={icon}
			/>

			<div className="absolute w-full bottom-0 left-1/2 -translate-x-1/2 text-center md:p-1">
				<span className="font-code text-[10px]">{title}</span>
			</div>
		</div>
	);
};

export default SkillElement;
