import React from "react";

import Section from "@components/Section";

interface props {}

const About: React.FC<props> = () => {
	return (
		<Section
			title="Sobre"
			className="bg-gradient-to-b from-darker-background to-background"
		>
			<div className="text-justify md:text-left">
				<p className="[&>span]:text-primary">
					Aos meus 14 anos descobrir minha paixão por criar coisas{" "}
					<span>novas</span>, <span>bonitas</span> e <span>funcionais</span>, e
					com isso decidi entrar na área de programação, desde então continuo
					aprimorando meus conhecimentos não somente em <span>Front-End</span>,{" "}
					<span>Back-End</span> e <span>Mobile</span>, mas também em{" "}
					<span>Design UI/UX</span>, <span>Sistemas Operacionais</span> e{" "}
					<span>Rede de Computadores</span>.
				</p>
			</div>

			<div className="flex justify-end">
				<img
					src="images/computer-ilustration.webp"
					className="hidden md:inline-block h-52"
				/>
			</div>
		</Section>
	);
};

export default About;
