import React, { useEffect, useState } from "react";

interface iconProp {
	name: string;
	link: string;
}

const SocialMediasPopUp: React.FC = () => {
	const [menuOpenedState, setMenuOpenedState] = useState(false);

	const IconLink: React.FC<iconProp> = ({ name, link }) => (
		<a
			href={link}
			target="_blank"
			rel="noreferrer"
			className="transition-all hover:text-primary"
		>
			<i className={name}></i>
		</a>
	);

	useEffect(() => {
		setTimeout(() => setMenuOpenedState(true), 2000);
	}, []);

	return (
		<div
			className="fixed z-50 left-3 w-10 transition-all duration-500"
			style={{ bottom: menuOpenedState ? 0 : "-9rem" }}
		>
			<div className="w-full h-full backdrop-blur-sm flex flex-col">
				<div
					className="w-full h-5 bg-primary flex items-center justify-center text-white cursor-pointer"
					onClick={() => setMenuOpenedState(!menuOpenedState)}
				>
					<i
						className="fa-solid fa-chevron-down text-xs transition-all duration-500"
						style={{ transform: `rotate(${menuOpenedState ? 0 : -180}deg)` }}
					/>
				</div>

				<div className="h-36 before:content-normal after:content-normal flex flex-col items-center justify-between text-white text-lg">
					<IconLink
						name="fa-brands fa-github"
						link="https://github.com/paulohcardoson"
					/>

					<IconLink
						name="fa-brands fa-telegram"
						link="https://t.me/paulohcardoson"
					/>

					<IconLink
						name="fa-brands fa-linkedin-in"
						link="https://www.linkedin.com/in/paulohcardoson/"
					/>

					<IconLink
						name="fa-brands fa-discord"
						link="https://discord.com/users/618570971812593670"
					/>
				</div>
			</div>
		</div>
	);
};

export default SocialMediasPopUp;
