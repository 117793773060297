import React, { useEffect, useState } from "react";

const EmailPopUp: React.FC = () => {
	const [displayingState, setDisplayingState] = useState(false);

	useEffect(() => {
		setTimeout(() => setDisplayingState(true), 2000);
	}, []);

	return (
		<div
			className="fixed z-50 bottom-0 transition-all duration-500"
			style={{ right: displayingState ? 10 : -20 }}
		>
			<div className="flex flex-col items-center">
				<a
					href="mailto:pauloh.cardoson@gmail.com"
					className="transition-all duration-500 pb-5 text-white/30 hover:text-primary hover:pb-10"
					style={{ writingMode: "vertical-lr" }}
				>
					<span className="font-code">pauloh.cardoson@gmail.com</span>
				</a>

				<div className="w-0.5 h-12 bg-white/10" />
			</div>
		</div>
	);
};

export default EmailPopUp;
