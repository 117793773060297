// import axios from "axios";
import React, { useState } from "react";

import LittleTerminal from "./components/LittleTerminal";
import IntroText from "./components/IntroText";

const Intro: React.FC = () => {
	// Intro text animation state
	const [introDisplayState, setIntroDisplayState] = useState(false);

	return (
		<section
			className="relative w-full h-screen bg-cover bg-center"
			style={{
				backgroundImage: "url(images/background.webp)",
			}}
		>
			{/* Background Layer */}
			<div className="absolute top-0 left-0 w-full h-full bg-darker-background opacity-90" />

			{/* Header */}
			<div className="absolute top-5 left-1/2 -translate-x-1/2 z-10">
				<LittleTerminal
					displayText="$HOME/scripts/greetings.sh"
					delayInMilliseconds={50}
					onFinish={() => setIntroDisplayState(true)}
				/>
			</div>

			{/* Main content */}
			<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
				<IntroText displayState={introDisplayState} />
			</div>
		</section>
	);
};

export default Intro;
