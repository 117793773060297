import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
console.log(process.env.PUBLIC_URL);
root.render(
	<BrowserRouter basename={process.env.PUBLIC_URL}>
		<React.StrictMode>
			<App />
		</React.StrictMode>
	</BrowserRouter>
);
