import React from "react";

// Sections
import Intro from "@sections/Intro";
import About from "@sections/AboutMe";
import Skills from "@sections/Skills";
import MyProjects from "@sections/MyProjects";
import Footer from "@sections/Footer";

import SocialMediasPopUp from "@components/SocialMediasPopUp";
import EmailPopUp from "@components/EmailPopUp";

const App: React.FC = () => {
	return (
		<main>
			<Intro />
			<About />
			<Skills />
			<MyProjects />
			<Footer />

			{/* Fixed on left */}
			<SocialMediasPopUp />

			{/* Fixed on right */}
			<EmailPopUp />
		</main>
	);
};

export default App;
