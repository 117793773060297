import React from "react";

import Section from "@components/Section";
import SkillElement from "./components/SkillElement";

const Skills: React.FC = () => {
	const skills: skill[] = [
		{
			title: "React",
			icon: "icons/react.svg",
			isHighlighted: true,
		},
		{
			title: "Next.js",
			icon: "icons/next.svg",
		},
		{
			title: "TailwindCSS",
			icon: "icons/tailwindcss.svg",
			isHighlighted: true,
		},
		{
			title: "Docker",
			icon: "icons/docker.svg",
		},
		{
			title: "PostgreSQL",
			icon: "icons/postgresql.svg",
		},
		{
			title: "Typescript",
			icon: "icons/typescript.svg",
			isHighlighted: true,
		},
		{
			title: "MongoDB",
			icon: "icons/mongodb.svg",
		},
		{
			title: "Figma",
			icon: "icons/figma.svg",
			isHighlighted: true,
		},
		{
			title: "Github Pages",
			icon: "icons/github.svg",
			isHighlighted: true,
		},
		{
			title: "AWS",
			icon: "icons/aws.svg",
		},
		{
			title: "PrismaORM",
			icon: "icons/prismaorm.svg",
		},
		{
			title: "GraphQL",
			icon: "icons/graphql.svg",
		},
	];

	return (
		<Section
			title="Habilidades"
			className="bg-gradient-to-b from-background to-darker-background"
		>
			{/* Left */}
			<div className="text-justify md:text-left">
				<p className="[&>span]:text-primary">
					Além de conhecimentos técnicos em minha área, também possuo{" "}
					<span>inglês intermediario</span>, experiencia em <span>design</span>{" "}
					e uma imensa <span>curiosidade</span> por coisas novas.
				</p>

				<p className="[&>span]:text-primary w-3/4 mx-auto text-center md:text-left md:mx-0 text-gray-300 text-[10px] font-normal font-code mt-2">
					A seguir, algumas das tecnologias que me especializei (As{" "}
					<span>destacadas</span> foram utilizadas na construção desse site).
				</p>
			</div>

			{/* Right */}
			<div className="flex justify-end">
				<div className="w-full md:w-auto overflow-auto md:overflow-visible grid gap-1.5 grid-cols-skill-elements-all grid-rows-skill-elements-horizontal md:grid-cols-skill-elements-3 md:grid-rows-skill-elements-vertical-4 lg:grid-cols-skill-elements-4 lg:grid-rows-skill-elements-vertical-3">
					{skills.map((skill, index) => (
						<SkillElement key={index} {...{ ...skill }} />
					))}
				</div>
			</div>
		</Section>
	);
};

export default Skills;
