import React from "react";

interface props extends repo {
	reversed?: boolean;
}

const Project: React.FC<props> = ({
	name,
	title,
	description,
	screenshot,
	techs,
	reversed = false,
}) => {
	const imagePosition = reversed ? "left" : "right";
	const textPosition = reversed ? "right" : "left";

	return (
		<div className="relative w-full min-h-[300px]">
			<div
				className="w-full min-h-full absolute top-1/2 -translate-y-1/2 rounded-md md:w-3/4"
				style={{
					[imagePosition]: 0,
					backgroundImage: `url(images/${screenshot}`,
					backgroundPosition: "center",
					backgroundSize: "cover",
				}}
			>
				<div className="absolute top-0 left-0 w-full h-full bg-background bg-opacity-90 md:bg-opacity-60" />
			</div>

			<div
				className={`absolute top-1/2 -translate-y-1/2 z-10 w-full md:w-3/5 p-8 md:p-0 text-left md:text-${textPosition} md:${textPosition}-0`}
			>
				<a
					href={`https://github.com/paulohcardoson/${name}`}
					className="transition-all hover:text-primary"
					target="_blank"
					rel="noreferrer"
				>
					<h3 className="font-bold text-xl">{title}</h3>
				</a>

				<div className="text-xs mt-5 mb-2.5 rounded-md md:bg-background md:p-8">
					<p>{description}</p>
				</div>

				<p className="font-code text-sm">
					{techs.map((tech) => (
						<span
							key={tech}
							className={`inline-block mr-4 ${reversed ? "mr" : "mr"}-4`}
						>
							{tech}
						</span>
					))}
				</p>
			</div>
		</div>
	);
};

export default Project;
