import React, { PropsWithChildren } from "react";

interface props {
	title: string;
	splited?: boolean;
	className?: React.HTMLAttributes<HTMLElement>["className"];
}

const Section: React.FC<PropsWithChildren<props>> = ({
	title,
	splited = true,
	className = "",
	children,
}) => {
	return (
		<section className={`w-full py-8 px-16 ${className}`}>
			<div className="text-center">
				<h2 className="text-white text-xl font-bold">{title}</h2>
			</div>

			<div className="mt-10">
				{splited ? (
					<div className="grid grid-cols-1 gap-y-5 md:gap-y-0 items-center md:grid-cols-[40%,55%]">
						{children}
					</div>
				) : (
					children
				)}
			</div>
		</section>
	);
};

export default Section;
