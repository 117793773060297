import React from "react";

const Footer: React.FC = () => {
	return (
		<footer className="text-center p-5 bg-darker-background">
			<span className="font-code text-xs text-white/50">
				Inspirado em{" "}
				<a
					href="https://v4.brittanychiang.com"
					target="_blank"
					rel="noreferrer"
					className="text-primary hover:underline"
				>
					Brittany Chiang
				</a>
			</span>
		</footer>
	);
};

export default Footer;
