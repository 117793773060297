import React from "react";

interface props {
	displayState: boolean;
}

const IntroText: React.FC<props> = ({ displayState }) => {
	return (
		<div className="text-center">
			<p
				className="font-code text-gray-300 transition-all duration-700"
				style={{
					position: "relative",
					top: displayState ? 0 : -10,
					opacity: displayState ? 1 : 0,
				}}
			>
				Olá, meu nome é
			</p>
			<p
				className="font-bold text-primary text-5xl transition-all duration-700 delay-200"
				style={{
					position: "relative",
					top: displayState ? 0 : -10,
					opacity: displayState ? 1 : 0,
				}}
			>
				Paulo Cardoso
			</p>
			<p
				className="font-medium text-gray-400 mt-5 transition-all duration-700 delay-500"
				style={{
					position: "relative",
					top: displayState ? 0 : -10,
					opacity: displayState ? 1 : 0,
				}}
			>
				Sou um <span className="text-primary">desenvolvedor FullStack</span> e{" "}
				<span className="text-primary">Mobile</span> apaixonado por
				computadores.
			</p>
		</div>
	);
};

export default IntroText;
