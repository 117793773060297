import React, { useEffect, useState } from "react";

interface props {
	displayText: string;
	delayInMilliseconds: number;
	onFinish: () => void;
}

const LittleTerminal: React.FC<props> = ({
	displayText,
	delayInMilliseconds,
	onFinish,
}) => {
	const [text, setText] = useState("");
	const [isCursorDisplaying, setCursorDisplayingState] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			const nextCharacter = displayText[text.length];

			if (nextCharacter) setText(text + nextCharacter);
			else onFinish();
		}, delayInMilliseconds);
	}, [text]);

	useEffect(() => {
		setTimeout(() => setCursorDisplayingState(!isCursorDisplaying), 1000);
	}, [isCursorDisplaying]);

	return (
		<div className="flex w-[300px] h-[40px] items-center justify-center backdrop-blur-sm py-2 rounded-lg text-white">
			<i className="fa-sharp fa-solid fa-chevron-right text-primary text-xs"></i>

			<span className="font-code font-bold ml-1">{text}</span>

			<div
				className="self-end ml-0.5 mb-1.5 w-2 h-0.5 bg-primary"
				style={{ opacity: isCursorDisplaying ? 1 : 0 }}
			/>
		</div>
	);
};

export default LittleTerminal;
