import React from "react";

import Section from "@components/Section";
import Project from "./components/Project";

const MyProjects: React.FC = () => {
	const repos: repo[] = [
		{
			name: "be-the-hero",
			title: "Be the Hero",
			description:
				"Aplicativo Web & Mobile + BackEnd para conectar voluntários e doadores a ONG's",
			screenshot: "be-the-hero.png",
			techs: ["React", "Node.js", "Database"],
		},
		{
			name: "proffy",
			title: "Proffy",
			description:
				"Aplicativo web & Mobile + BackEnd para conectar professores e estudantes.",
			screenshot: "proffy.png",
			techs: ["React", "Node.js", "Database"],
		},
		{
			name: "rich-text-editor-electron",
			title: "Rich Text Editor",
			description: "Aplicativo Web e Desktop funcional para edição de texto.",
			screenshot: "rich-text-editor-electron.png",
			techs: ["React"],
		},
	];

	return (
		<Section
			title="Meus Projetos"
			splited={false}
			className="bg-darker-background"
		>
			<div className="flex flex-col items-center">
				{repos.map((repo, index) => (
					<div key={index} className="w-full md:w-4/5 max-w-4xl mt-10">
						<Project {...{ ...repo }} reversed={index % 2 === 0} />
					</div>
				))}
			</div>
		</Section>
	);
};

export default MyProjects;
